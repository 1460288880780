import { BaseComponent, Component, Prop, namespace } from '@zento-lib/components';
import { CustomFormsStore } from 'theme/stores/customForms/customForms';
import { Form } from 'theme/components/Form/Form';
import { Input } from 'theme/components/Form/Input';
import { Select } from 'theme/components/Form/Select';
import { Checkbox } from 'theme/components/Form/Checkbox';
import { RadioGroup } from 'theme/components/Form/RadioGroup';
import type { IFormValidation } from 'theme/components/Form/types';
import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';
import type { FormFieldsTypes } from '@zento/modules/organism/types/FormData';

import type { IFormFieldsLocation } from './FormFieldsLocation.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(AppContextKey);

@Component({})
@Form()
export class FormFieldsLocation extends BaseComponent<IFormFieldsLocation, unknown & IFormValidation> {
  /**
   * Array of all form fields
   */
  @Prop({ type: Array, required: true })
  formFields: FormFieldsTypes[];

  /**
   * Determines custom form object
   */
  @Prop({ type: Object, required: true })
  customForm?: any;

  @appContextStore.Getter('isServer')
  protected isServer: boolean;

  protected formStore = new CustomFormsStore();

  data() {
    return {
      conditions: false,
      values: [],
      formFieldItems: [],
    };
  }

  /**
   * Determines fields once form fields are filled
   */
  private renderFields() {
    if (this.$data.formFieldItems !== null && !this.$data.formFieldItems.length) {
      this.formFields.forEach((f) => {
        const dropdownValues = this.removeSpecialChars(f.dropdown_values, '.');
        const fieldName = f.label ? f.label : dropdownValues;
        const value = this.removeSpecialChars(fieldName, '.');

        this.$data.values[value] = f[value];
      });

      this.$data.formFieldItems = [...this.formFields];
    }
  }

  /**
   * Remove special characters for rendering correct state value
   */
  private removeSpecialChars(value, char) {
    const fieldValue = value.replace(/[^a-zA-Z0-9]/g, char);

    return fieldValue;
  }

  beforeUpdate() {
    this.renderFields();
  }

  beforeMount() {
    this.renderFields();
  }

  mounted() {
    this.$data.values = this.formStore.fieldsLocation;
  }

  render() {
    return (
      <div class={style.formWrapperLocation}>
        <div data-zento-form='true'>
          {[...(this.$data.formFieldItems || [])]
            .sort((a: FormFieldsTypes, b: FormFieldsTypes) => parseInt(a.position) - parseInt(b.position))
            .map((field: FormFieldsTypes) => {
              const dropdownValues = this.removeSpecialChars(field.dropdown_values, '.');
              const fieldName = field.label ? field.label : dropdownValues;
              const testId = this.removeSpecialChars(fieldName, '.') + '-' + field.type;
              const value = this.removeSpecialChars(fieldName, '.');

              return [
                field.type !== 'select' && field.type !== 'checkbox' && field.type !== 'radio' ? (
                  <div
                    class={{
                      [style.file]: field.type === 'file',
                    }}>
                    <Input
                      state={this.$data.values}
                      valueKeeper={value}
                      name={fieldName}
                      type={field.type}
                      validateOn='input'
                      required={field.is_required === '1'}
                      minLength={field.type !== 'date' ? 2 : null}
                      pattern={field.pattern}
                      pristinePolicy={field.type !== 'date' ? 'poi' : 'poe'}
                      labelStyle={field.type === 'date' ? 'static-top' : this.extended.$config.zento.theme.labelStyle}
                      onChange={this.fieldChanged}
                      class={{ [style.date]: field.type === 'date' }}
                      key={field.label + field.type + '-input'}
                      dataTestId={testId}>
                      {field.type !== 'hidden' ? <span slot='label'>{field.label}</span> : null}
                    </Input>

                    {this.formStore.loaderImage && field.type === 'file' ? (
                      <div class={style.progress}>
                        <div class={style.progressValue} />
                      </div>
                    ) : null}
                  </div>
                ) : field.type === 'select' ? (
                  <Select
                    state={this.$data.values}
                    valueKeeper={value}
                    name={fieldName}
                    items={field.dropdown_values.split(',').map((opt) => ({
                      value: this.removeSpecialChars(opt, '').toLocaleLowerCase(),
                      label: opt,
                    }))}
                    required={field.is_required === '1'}
                    onChange={this.fieldChanged}
                    class={style.selectField}
                    key={field.label + '-select-input'}
                    dataTestId={testId}>
                    <span slot='label'>{field.label}</span>
                  </Select>
                ) : field.type === 'checkbox' ? (
                  <Checkbox
                    state={this.$data.values}
                    valueKeeper={value}
                    name={fieldName}
                    type={field.type}
                    validateOn='change'
                    required={field.is_required === '1'}
                    checked={true}
                    label={field.label}
                    onChange={this.fieldChanged}
                    class={style.checkboxField}
                    key={field.label + '-checkbox-input'}
                    dataTestId={testId}
                  />
                ) : field.type === 'radio' ? (
                  [
                    <span slot='label'>{field.label}</span>,
                    <RadioGroup
                      state={this.$data.values}
                      valueKeeper={value}
                      name={fieldName}
                      type='radio'
                      items={field.dropdown_values.split(',').map((opt) => ({
                        value: this.removeSpecialChars(opt, '').toLocaleLowerCase(),
                        label: opt,
                      }))}
                      validateOn='change'
                      onChange={this.fieldChanged}
                      class={style.radioBox}
                      required={field.is_required === '1'}
                      key={field.label + '-radio-input'}
                      dataTestId={testId}
                    />,
                  ]
                ) : null,
              ];
            })}
        </div>
      </div>
    );
  }

  /**
   * Determines filled fields
   */
  private fieldChanged() {
    setTimeout(() => {
      const preSignedPost = JSON.parse(this.customForm.preSignedPost);

      this.formStore.fieldsLocation = this.$data.values;

      if (preSignedPost !== null && Object.keys(preSignedPost).length && 'action' in preSignedPost) {
        const inputs: HTMLInputElement[] = Array.from(this.$el.querySelectorAll('div[data-zento-form] input'));

        this.formStore.formDataInputs = inputs;
      }
    }, 500);
  }
}
